<template>
  <div class="clearBox">
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="content-box" style="padding-top: 24px">
      <div class="content-main">
        <!-- 标题 -->
        <div>
          <span class="title">{{nav[0].name}}</span>
        </div>
        <!-- 左侧非地图部分 -->
        <div class="content-main-left">
          <!-- 标段输入项 -->
          <div class="content-main-hw" style="padding-top: 0px">
            <el-form
              :model="form"
              :rules="rules"
              ref="form"
              :inline="true"
              label-position="right"
              label-width="170px"
              size="mini"
            >
              <el-form-item label="公厕名称：" style="margin-bottom: 0px">{{form.wcName}}</el-form-item>
              <el-form-item label="所属标段：">
                {{form.bidName}}
              </el-form-item>
              <el-form-item label="开放时间：" style="width: 100%">
                {{form.openStartTime}} 至 {{form.openEndTime}}
              </el-form-item>
              <el-form-item label="管理单位名称：">{{form.manageUnit}}</el-form-item>
              <el-form-item label="管理类别：">
                <el-select
                  v-model="form.manageType"
                  disabled
                  placeholder="请选择"
                  filterable
                >
                  <el-option
                    v-for="(item, idx) in $enums_hw.manageType.list"
                    :key="idx"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
              <el-form-item label="管理配员(名)：">{{form.demandWorker}}</el-form-item>
              <el-form-item label="建成时间：">
                <el-date-picker
                  v-model="form.buildTime"
                  type="date"
                  value-format="timestamp"
                  placeholder="未指定"
                  disabled
                ></el-date-picker>
              </el-form-item>
              <el-form-item label="管理人员电话：">{{form.demandWorkerPhone}}</el-form-item>
              <el-form-item label="管理人员姓名：">{{form.demandWorkerName}}</el-form-item>
              <el-form-item label="男厕站位数量：">{{form.maleStandNum}}</el-form-item>
              <el-form-item label="占地面积(m²)：">{{form.area}}</el-form-item>
              <el-form-item label="女厕蹲位数量：">{{form.femaleSquatNum}}</el-form-item>
              <el-form-item label="男厕蹲位数量：">{{form.maleSquatNum}}</el-form-item>
              <el-form-item label="镜子数量：">{{form.mirrorNum}}</el-form-item>
              <el-form-item label="水龙头数量：">{{form.faucetNum}}</el-form-item>
              <el-form-item label="风扇数量：">{{form.fanNum}}</el-form-item>
              <el-form-item label="抽风机数量：">{{form.suctionFanNum}}</el-form-item>
              <el-form-item label="公厕类别：">
                <el-select v-model="form.wcType" disabled placeholder="未指定" filterable>
                  <el-option
                    v-for="(item, idx) in $enums_hw.wcType.list"
                    :key="idx"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
              <el-form-item label="投资金额估算(万元)：">{{form.investFund}}</el-form-item>
              <el-form-item label="经费标准(元/座)：">{{form.fundStandard}}</el-form-item>
              <el-form-item label="是否独立建筑：">
                <el-checkbox disabled v-model="form.isIndependent">是</el-checkbox>
              </el-form-item>
              <el-form-item label="有无洗手液：">
                <el-checkbox disabled v-model="form.hasHandSanitizer">有</el-checkbox>
              </el-form-item>
              <el-form-item label="有无化粪池：">
                <el-checkbox disabled v-model="form.hasSepticTank">有</el-checkbox>
              </el-form-item>
              <el-form-item label="有无收费：">
                <el-checkbox disabled v-model="form.freeOrNot">有</el-checkbox>
              </el-form-item>
              <el-form-item label="有无免费厕纸供应：">
                <el-checkbox disabled v-model="form.hasFreePaper">有</el-checkbox>
              </el-form-item>
              <el-form-item label="地址：" style="width: 100%">{{form.wcAddress}}</el-form-item>
              <!-- 公厕附件-->
              <el-form-item label="公厕附件：" style="width: 100%">
                <ayl-image v-model="form.wcImageUrls"></ayl-image>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <!-- 地图部分 -->
        <ayl-map v-model="mapData" :enableDraw="false" icon="gc"></ayl-map>
      </div>

      <div class="tac mt40px">
        <el-button type="danger" class="btn-danger" style="margin-left: 10px" @click="onClose">关闭</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import edit from "@/mixins/edit";
export default {
  mixins: [edit],

  data() {
    return {
      nav: [{ name: "公厕详情" }],
      mapData: {
        //地图数据
        lnglat: null, //经纬度，数组，形如[118,36]
        scope: null //标段范围，经纬度点阵数组，形如[[118,36],[117,32],[116,113]]
      },
      form: {},
      rules: {}
    };
  },
  methods: {
    /**
     * @summary【钩子函数，函数名固定】
     * @description 获取编辑的数据。此函数内，只需要专注操作表单数据this.form，不用管任何UI逻辑。
     * @author mabo
     */
    async getInfo() {
      let self = this;
      let formData = await self.$api_hw.publicToilets_getById({
        id: self.$route.query.id
      });

      self.form = _fixData(formData);

      /**
       * 把服务器端的Data 修改一下以方便编辑
       */
      function _fixData(d) {
        //先检查key是否存在，不存在赋值null
        ["wcCoordinateScope", "bidScope"].forEach(key => {
          if (!d.hasOwnProperty(key)) {
            d[key] = null;
          }
        });
        for (let key in d) {
          switch (key) {
            case "isIndependent":
            case "hasHandSanitizer":
            case "hasSepticTank":
            case "freeOrNot":
            case "hasFreePaper":
              d[key] = !!d[key];
              break;
            case "wcCoordinateScope":
              try {
                self.mapData.lnglat = JSON.parse(d[key]);
              } catch (e) {
                self.mapData.lnglat = null;
              }
              break;
            case "bidScope":
              self.mapData.move2center = true;
              try {
                self.mapData.scope = JSON.parse(d[key]);
              } catch (e) {
                self.mapData.scope = null;
              }
              break;
          }
        }
        return d;
      }
    }
  }
};
</script>

<style lang='sass' scoped>
.clearBox
  .content-box
    .content-main
      display: inline-block
      width: 100%
      &-left
        float: left
        width: 808px
      &-right
        float: left
        height: 777px
        width: calc(100% - 808px)
        background: #EDEDED
  .order
    overflow-x: visible !important
    &-type
      display: inline-block
      padding-right: 14px
      font-size: 14px
      font-family: Microsoft YaHei
      font-weight: 400
      color: rgba(102,102,102,1)
  .title
    width: 64px
    height: 17px
    font-size: 16px
    font-family: Microsoft YaHei
    font-weight: 400
    color: $-color-primary-3
    line-height: 18px
  .el-checkbox-group
    display: inline-block
  .map-popover
    position: absolute
    z-index: 2
    top: 12px
    right: 12px
    width: 180px
    height: 32px
    background: rgba(255,255,255,1)
    box-shadow: 0px 2px 8px 0px rgba(31,46,41,0.3)
    border-radius: 2px

  /deep/ .el-radio__input.is-checked .el-radio__inner
    background: $-color-primary-3
    border-color: $-color-primary-3
  /deep/ .el-radio__input.is-checked + .el-radio__label
    color: $-color-primary-3
  /deep/ .el-checkbox__input.is-checked .el-checkbox__inner
    background: $-color-primary-3
    border-color: $-color-primary-3
  /deep/ .el-checkbox__input.is-checked + .el-checkbox__label
    color: $-color-primary-3
  /deep/ .el-form-item
    width: 48%
  /deep/ .el-input--mini
    width: 170px
  .el-date-editor
    width: 170px
  .el-date-editor-bigwidth
    width: 350px
  .el-input-bigwidth
    width: 350px
</style>
