/**
 * edit
 * 为了【编辑】功能而做的公用函数，方便mixin组合进入具体的【编辑型views】
 * @author mabo
 */
export default {
    data() {
        return {
            isLoading: false,
        }
    },

    methods: {
        //标段下拉
        handleBidChage(value) {
            let self = this;
            self.form.bidId = value[1]; //所属标段
            self.log("i am from mixin,cu.js")
            if (self.mapData && self.form.bidId !== "") {
                self.mapData.move2center = true;
                self.$api_hw.common_getBidInfo({
                    id: self.form.bidId
                }).then(res => {
                    self.log('mk', res.bidCoordinateScope);
                    try {
                        self.mapData.scope = Array.isArray(res.bidCoordinateScope) ? res.bidCoordinateScope : JSON.parse(res.bidCoordinateScope);
                    } catch (e) {
                        self.mapData.scope = null;
                    }
                }).catch(() => {
                    self.mapData.scope = null;
                });

            }
        },
        //关闭当前页面
        onClose(toPath) {
            if (toPath && typeof toPath == "string")
                // for 标签页
                BUS.$emit(BUSEVENT.CLOSE_NAV, [this.$route, toPath]);
            else {
                BUS.$emit(BUSEVENT.CLOSE_NAV, this.$route);
            }
            // for 弹窗
            this.$emit("onClose");
        },
        async onSubmit() {
            var self = this;
            if (self.isLoading) return; //防止重复提交
            self.$refs.form.validate(async valid => {
                if (!valid) return;
                self.isLoading = true;
                try {
                    await self.submit();
                    self.$notify({
                        title: "成功",
                        message: `${self.nav[0].name}成功`,
                        type: "success"
                    });
                    BUS.$emit(BUSEVENT.makeEvent("refresh", self.$route.query.from), self.$route.query.id);
                    self.onClose("../" + self.$route.query.from);
                    // self.$router.push("../"+self.$route.query.from);
                } catch (e) {
                    console.error(e);
                } finally {
                    self.isLoading = false;
                }
            });
        }
    },

    computed: {
        visitedViews() {
            return this.$store.state.tagsView.visitedViews;
        },
        routes() {
            return this.$store.state.permission.routes;
        }
    },

    async activated() {
        await this.getInfo();
    },
}